/* eslint-disable @typescript-eslint/no-unused-vars */
import { pick } from 'lodash/fp';
import { parse } from 'query-string';
import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Annotations, Moment } from 'weplayed-typescript-api';

import {
  AnnotationsCanvas, AnnotationsPanel,
} from 'common/components/Annotations';
import { useApplication } from 'common/hooks/useApplication';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { usePrevious } from 'common/hooks/usePrevious';
import { useProfile } from 'common/hooks/useProfile';
import { useWS } from 'common/hooks/useWS';
import {
  GameEvents, WSGameAction, WSGameUpdateAction,
} from 'common/hooks/useWS/types';
import { EventContext } from 'common/utils/events';
import { getOwnTeam } from 'common/utils/games';
import { pickByPk } from 'common/utils/helpers';
import {
  momentViewEvent, sortMomentsByTimeWithUser,
} from 'common/utils/moments';
import { roundTimeToHalfSecond } from 'common/utils/timeCalcs';
import {
  FINISH_VIDEO_OFFSET, getVideoSpan, START_VIDEO_OFFSET,
} from 'common/utils/timeSpan';

import {
  MomentActionsProviderRefType,
} from 'cms/components/MomentActions/types';
import { useGame } from 'cms/hooks/useGame';
import { useMoments } from 'cms/hooks/useMoments';

import { empty, GameProviderContext } from './constants';
import {
  FilterControlTags, FilterTag, FilterTagType, GameProviderContextType,
  HubEntries, MODE, UrlParams, UrlProps,
} from './types';
import {
  extractMomentsTags, filterMomentsForReview, testMoment, testPbp,
} from './utils';

export const GameProvider: React.FC = function GameProvider({ children }) {
  const match = useRouteMatch<{ uid: string; muid?: string }>();
  const location = useLocation();
  const { broadcast } = useApplication();

  const position = React.useRef(0);

  const params = React.useMemo<UrlProps & UrlParams>(() => {
    const qs = parse(location.search) as Record<keyof UrlParams, string | undefined>;

    return {
      loopFrom: qs.loopFrom && parseFloat(qs.loopFrom),
      loopto: qs.loopTo && parseFloat(qs.loopTo),
      momentId: match.params.muid,
      uid: match.params.uid,
      videoId: qs.videoId,
    };
  }, [match, location]);

  const annotationsPanel = React.useRef<AnnotationsPanel>();
  const annotationsCanvas = React.useRef<AnnotationsCanvas>();
  const momentsAction = React.useRef<MomentActionsProviderRefType>();

  const [mode, setMode] = React.useState<MODE>(MODE.NONE);
  const [annotations, onAnnotations] = React.useState<Annotations>();
  const [highlighted, setHighlighted] = React.useState<Moment[]>();
  const [filters, onFilters] = React.useState<FilterTag[]>([]);

  const [requestedPosition, positionTo] = React.useState<number>();
  const [atLiveEdge, setAtLiveEdge] = React.useState(false);
  const [duration, setDuration] = React.useState(null);
  const [moment, onMoment] = React.useState<Moment>();
  const [current, setCurrent] = React.useState<Moment>();
  const [requestedMoment, setRequestedMoment] = React.useState<Moment>();
  const [state, setState] = React.useState(true);
  const [requestedState, stateTo] = React.useState(true);
  const [adjusted, setAdjusted] = React.useState(false);
  const [cms, onCMS] = React.useState<string>();

  const {
    profile,
    subscription: { recent_team: recentTeam },
    setRecentTeam: [setRecentTeam],
  } = useProfile();
  const { subscribe, unsubscribe } = useWS();
  const {
    game: gameQ,
    pbp: pbpQ,
    qa: [onQA, qaState],
    createPivotalPlaylist: [onCreatePlaylist, createPivotalPlaylistState],
    endStream: [onEndStream, endStreamState],
  } = useGame({
    ...pick(['uid', 'momentId', 'videoId'], params),
    cms,
  });

  const {
    create: [create, createState],
    update: [update, updateState],
  } = useMoments();
  useHandleQuery(gameQ);

  const isLive = gameQ.data?.live_now === true;

  React.useEffect(() => {
    if (isLive) {
      const listener = (message: WSGameAction): void => {
        if (message.action === GameEvents.LEAVE) {
          unsubscribe('game', gameQ.data.pk, listener);
          gameQ.refetch();
        } else if (message.action === GameEvents.UPDATED) {
          const { moments, pbp } = message as WSGameUpdateAction;
          if (moments) {
            gameQ.refetch();
          }

          if (pbp) {
            pbpQ.refetch();
          }
        }
      };

      subscribe('game', gameQ.data.pk, listener);

      return (): void => {
        unsubscribe('game', gameQ.data.pk, listener);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLive]);

  const video = gameQ.data?.video;

  const allHub: HubEntries = React.useMemo(() => {
    if (gameQ.data?.pk === params.uid
        && pbpQ.data?.game_id === params.uid
        && pbpQ.data.segments.length !== 0
    ) {
      const mmap = Object.fromEntries(gameQ.data.moments.map((m) => [m.pk, m]));
      const rest = [...gameQ.data.moments];

      const data = pbpQ.data.segments.map(({ actions }, sidx) => {
        const entries = actions.reduce(
          (acc, action) => {
            const pk = action.best_moment?.moment_id;
            let m: Moment = null;

            if (pk) {
              if (mmap[pk]) {
                m = mmap[pk];
                // remove from map to mark it as done
                delete mmap[pk];
              } else {
                // 1:
                // it is possible that this moment has been already
                // inserted above (time error) so we trying to find
                // it earlier in the current segment and attach here

                const aidx = acc.findIndex(([, mm]) => mm?.pk === pk);

                if (aidx !== -1) {
                  ([[, m]] = acc.splice(aidx, 1));
                }
              }
            }

            // find a moment from rest and splice all moments above
            // into resulting array before current entry
            let mm: Moment[] = [];

            if (m) {
              const ridx = rest.indexOf(m);

              // in fact this should not happen
              if (ridx !== -1) {
                mm = rest.splice(0, ridx + 1);
                // remove the last one
                mm.pop();
                // remove from dict
                mm.forEach((mmm) => { delete mmap[mmm.pk]; });
              }
            }

            return [
              ...acc, // could be modified earlier in 1:
              ...mm.map((mmm) => [null, mmm]), // moments happened earlier this one
              [action, m], // action itself, moment could be null
            ];
          },
          [],
        );

        // it is still possible that there are moments left still,
        // filter them by time and append to the end
        if (gameQ.data.video.segment_start_times) {
          // find a max time for the current segment
          const end = gameQ.data.video.segment_start_times[sidx + 1] ?? Number.POSITIVE_INFINITY;
          const midx = rest.findIndex((mm) => mm.start > end);

          const mm = rest.splice(0, midx === -1 ? rest.length : midx);
          entries.push(...mm.map((mmm) => {
            delete mmap[mmm.pk];
            return [null, mmm];
          }));
        }

        return {
          name: pbpQ.data.segment_names_full[sidx],
          short: pbpQ.data.segment_names[sidx],
          entries,
        };
      });

      if (rest.length) {
        // This part is definitely needs TBD
        // remaining moments in the rest means that we have no video.segment_start_times
        // and no moments inside PBP entries so we do not know where to put these in
        // so let just append them into the end of the last PBP chunk.

        data[data.length - 1].entries.push(...rest.map((mmm) => [null, mmm]));
      }

      return data;
    }
  }, [gameQ.data, params.uid, pbpQ.data]);

  const [hub, moments] = React.useMemo(() => {
    const ff = filters.filter(
      (f) => f.type !== FilterTagType.CONTROL || f.value !== FilterControlTags.PLAY_BY_PLAY,
    );

    const onlyPbp = ff.length !== filters.length;

    const mm = onlyPbp ? [] : gameQ.data?.moments.filter(testMoment(ff, profile));

    const result = allHub?.map((segment) => {
      const filtered = segment.entries.filter(([p, m]) => {
        const hasPbp = p && testPbp(ff, p);

        if (onlyPbp) {
          return hasPbp && !m;
        }

        // sometimes pbp text may differ from moment description so if
        // moment did not match butpbp did, we're going to include moment
        // into results as well
        if (hasPbp && m && !mm.includes(m)) {
          mm.push(m);
        }

        return hasPbp || (m && mm.includes(m));
    });

      return {
        ...segment,
        entries: filtered,
      };
    });

    return [
      result,
      gameQ.data?.moments.filter((m) => mm.includes(m)),
    ];
  }, [gameQ.data?.moments, allHub, filters, profile]);

  const segments = React.useMemo(() => (pbpQ.data
    ? video?.segment_start_times?.map((time, idx) => ({
      time,
      name: pbpQ.data.segment_names[idx],
    })) : null), [pbpQ.data, video?.segment_start_times]);

  const suggestions: FilterTag[] = React.useMemo(() => {
    const { tags, teams, players } = extractMomentsTags(moments);

    return [...tags, ...players, ...teams];
  }, [moments]);

  React.useEffect(() => {
    if (video?.duration) {
      setDuration(video.duration);
    }
  }, [video?.duration]);

  const findMoment = React.useCallback(
    (momentPk: string): Moment | undefined => moments?.find(pickByPk(momentPk)),
    [moments],
  );

  const jumpToMoment = React.useCallback((m?: Moment, playing = false): void => {
    if (m) {
      if (m.pk !== moment?.pk) {
        momentViewEvent(m.pk, EventContext.GAME);
      }

      setRequestedMoment(m);
      if (playing) {
        stateTo(true);
      }
      positionTo(m.start);
    }
  }, [moment]);

  const prevUid = usePrevious(gameQ.data?.pk);

  React.useEffect(() => {
    if (gameQ.data && gameQ.data.pk !== prevUid && !gameQ.data.live_now) {
      if (params.loopFrom) {
        positionTo(params.loopFrom);
      } else {
        const m = params.momentId ? findMoment(params.momentId) : gameQ.data.moments[0];
        if (m) {
          jumpToMoment(m, true);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameQ.data?.pk]);

  React.useEffect(() => {
    if (gameQ.data && !recentTeam && profile?.org?.teams) {
      const team = getOwnTeam(gameQ.data, profile.org.teams);
      if (team) {
        setRecentTeam(team);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameQ.data?.pk, profile]);

  React.useEffect(() => {
    if (mode === MODE.NONE && moment !== current) {
      onMoment(current);
    }
  }, [mode, moment, current]);

  React.useEffect(() => {
    if (moment === requestedMoment) {
      setRequestedMoment(null);
    }
  }, [moment, requestedMoment]);

  const prevMoment = usePrevious(moment);
  React.useEffect(() => {
    if (mode !== MODE.NONE && prevMoment && moment
        && (prevMoment.start !== moment.start || prevMoment.end !== moment.end)) {
      setAdjusted(true);
    }
  }, [mode, prevMoment, moment]);

  const onCreateMoment = React.useCallback(
    (pos?: number, text?: string, copied_pbp_id?: number) => {
      const time = pos ?? requestedPosition ?? position.current ?? 0;
      const [start, end] = atLiveEdge
        ? [
          Math.max(
            0,
            roundTimeToHalfSecond(time)
              - FINISH_VIDEO_OFFSET
              - START_VIDEO_OFFSET,
            ),
          pos,
        ]
        : getVideoSpan(time, 0, duration);

      // reset all filters
      onFilters([]);

      const m: Moment = {
        name: text || '',
        annotations: empty,
        can_manage: true,
        copied_pbp_id,
        curator: profile,
        description: text || '',
        end,
        game_id: params.uid,
        game: gameQ.data,
        pk: null,
        start,
        video,
      };

      setCurrent(m);
      onMoment(m);

      setMode(MODE.EDIT);
      positionTo(start);
      stateTo(false);
      setAdjusted(false);
    },
    [atLiveEdge, requestedPosition, position, duration, profile, params.uid, gameQ.data, video],
  );

  const onEditMoment = React.useCallback((pk: string) => {
    const m = pk ? findMoment(pk) : null;
    if (m) {
      onMoment({
        ...m,
        game: gameQ.data,
        game_id: gameQ.data.pk,
        video,
      });
      setMode(MODE.EDIT);
      stateTo(false);
      positionTo(m.start);
      setAdjusted(true);
    } else {
      onMoment(null);
      setMode(MODE.NONE);
      stateTo(true);
    }
  }, [findMoment, gameQ.data, video]);

  const onMomentAnnotateStart = React.useCallback(() => {
    if (mode === MODE.EDIT) {
      onAnnotations(moment.annotations || empty);
      setMode(MODE.ANNOTATIONS);
    }
  }, [mode, moment]);

  const onMomentAnnotateEnd = React.useCallback((commit?: boolean) => {
    if (mode === MODE.ANNOTATIONS) {
      if (commit) {
        onMoment({
          ...moment,
          annotations,
        });
      } else if (commit === undefined) {
        onMoment({
          ...moment,
          annotations: [],
        });
      }
      onAnnotations(null);
      setMode(MODE.EDIT);
    }
  }, [annotations, mode, moment]);

  const onPosition = React.useCallback(
    ($position: number, $duration: number, $atLiveEdge?: boolean) => {
      position.current = $position;

      const $current = moments && sortMomentsByTimeWithUser(
        moments.filter(
          ({ start, end }: Moment) => start <= position.current && end >= position.current,
        ),
        profile?.pk,
        // we need to have event with closest `start` value to position so
        // revert content
        true,
      ).shift();

      setCurrent($current);

      if (typeof $duration === 'number') {
        setDuration($duration);
      }
      setAtLiveEdge($atLiveEdge);
      positionTo(null);
    },
    [moments, profile?.pk],
  );

  const onJumpTo = React.useCallback((m: Moment) => {
    jumpToMoment(m, true);
  }, [jumpToMoment]);

  const onState = React.useCallback((s: boolean) => {
    stateTo(null);
    setState(s);
  }, []);

  const onMomentSelect = React.useCallback((m: Moment) => {
    jumpToMoment(m);
  }, [jumpToMoment]);

  const focusTimer = React.useRef<ReturnType<typeof setTimeout>>();

  const onFocusMoments = React.useCallback(
    (mm?: Moment[]): void => {
      clearTimeout(focusTimer.current);
      focusTimer.current = setTimeout(() => {
        setHighlighted(mm);
      }, 200);
    },
    [],
  );

  React.useEffect(() => (): void => clearTimeout(focusTimer.current), []);

  const onMomentCommit = React.useCallback(() => {
    if (mode === MODE.EDIT && moment) {
      if (moment.pk) {
        update({ uid: moment.pk, moment });
      } else {
        if (!adjusted) {
          broadcast('warning', "Please make sure to first adjust moment's timing", 2000);

          return;
        }

        create({ moment });
      }
    }
  }, [adjusted, broadcast, create, mode, moment, update]);

  const onMomentDelete = React.useCallback((m: Moment) => {
    momentsAction.current?.({
      action: 'delete',
      moment: m,
    });
  }, []);

  const saving = createState.isLoading || updateState.isLoading;

  React.useEffect(() => {
    if (createState.isSuccess) {
      broadcast('info', 'Moment created successfully');
    }
  }, [createState.isSuccess, broadcast]);

  React.useEffect(() => {
    if (updateState.isSuccess) {
      broadcast('info', 'Moment updated successfully');
    }
  }, [updateState.isSuccess, broadcast]);

  const prevSaving = usePrevious(saving);
  React.useEffect(() => {
    if (prevSaving && !saving) {
      onMoment(null);
      stateTo(true);
      setMode(MODE.NONE);
    }
  }, [prevSaving, saving]);

  const loading = gameQ.isFetching || pbpQ.isFetching
    || qaState.isLoading || createPivotalPlaylistState.isLoading || endStreamState.isLoading;

  const toReview = React.useMemo(
    () => (moments ? filterMomentsForReview(moments) : []),
    [moments],
  );

  const value: GameProviderContextType = {
    ...params,
    annotations,
    annotationsCanvas,
    annotationsPanel,
    atLiveEdge,
    cms,
    duration,
    filters,
    findMoment,
    game: gameQ?.data,
    highlighted,
    hub,
    loaded: Boolean(gameQ.data),
    loading,
    mode,
    // makes possible editing when autoplay is disabled
    moment: requestedMoment && mode === MODE.NONE
      ? ((requestedMoment?.pk === moment?.pk && moment) || requestedMoment)
      : moment,
    moments,
    momentsAction,
    onAnnotations,
    onCMS,
    onCreateMoment,
    onCreatePlaylist,
    onEditMoment,
    onEndStream,
    onFilters,
    onFocusMoments,
    onJumpTo,
    onMoment,
    onMomentDelete,
    onMomentAnnotateEnd,
    onMomentAnnotateStart,
    onMomentCommit,
    onMomentSelect,
    onPosition,
    onQA,
    onState,
    positionTo,
    recentTeam,
    requestedPosition,
    requestedState,
    saving,
    segments,
    state,
    stateTo,
    suggestions,
    toReview,
    user: profile,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const context: GameProviderContextType = React.useMemo(() => value, Object.values(value));

  return (
    <GameProviderContext.Provider value={context}>
      {children}
    </GameProviderContext.Provider>
  );
};
